import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

function Navigation() {
  const { state: { role }, setRole } = useContext(AppContext);

  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/" className="navbar-brand">Blitzschnell</Link>
        <select onChange={(e) => setRole(e.target.value)} value={role}>
          <option value="client">Client</option>
          <option value="master">Master</option>
          <option value="admin">Admin</option>
        </select>
        <ul className="navbar-nav">
          {role === 'client' && (
            <>
              <li className="nav-item">
                <Link to="/client-dashboard" className="nav-link">Client Dashboard</Link>
              </li>
              <li className="nav-item">
                <Link to="/request" className="nav-link">Request</Link>
              </li>
              <li className="nav-item">
                <Link to="/chat" className="nav-link">Chat</Link>
              </li>
            </>
          )}
          {role === 'master' && (
            <>
              <li className="nav-item">
                <Link to="/offers" className="nav-link">Offers</Link>
              </li>
              <li className="nav-item">
                <Link to="/master-settings" className="nav-link">Settings</Link>
              </li>
            </>
          )}
          {role === 'admin' && (
            <>
              <li className="nav-item">
                <Link to="/admin-masters" className="nav-link">Masters</Link>
              </li>
              <li className="nav-item">
                <Link to="/admin-requests" className="nav-link">Requests</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;
