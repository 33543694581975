import React, { createContext, useState, useEffect } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    role: 'master', // ���� �� ��������� - ������ ��� ������������
    user: null,
    offers: [],
    requests: [],
    notifications: [],
  });

  // ������� ��� ��������� ����
  const setRole = (newRole) => {
    setState((prevState) => ({
      ...prevState,
      role: newRole,
    }));
  };

  // ������� ��� �������� �����������
  const fetchOffers = async () => {
    try {
      const response = await fetch('/api/offers');
      const data = await response.json();
      setState((prevState) => ({
        ...prevState,
        offers: data,
      }));
    } catch (error) {
      console.error('Failed to fetch offers:', error);
    }
  };

  // ������� ��� �������� ������
  const fetchRequests = async () => {
    try {
      const response = await fetch('/api/requests');
      const data = await response.json();
      setState((prevState) => ({
        ...prevState,
        requests: data,
      }));
    } catch (error) {
      console.error('Failed to fetch requests:', error);
    }
  };

  // ������� ��� �������� �����������
  const fetchNotifications = async () => {
    try {
      const response = await fetch('/api/notifications');
      const data = await response.json();
      setState((prevState) => ({
        ...prevState,
        notifications: data,
      }));
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  // �������� ������ ������� ��� ������� � id = 1 �� ��������� ��� ������������
  useEffect(() => {
    const loadDefaultUser = async () => {
      try {
        let userResponse;
        if (state.role === 'master') {
          userResponse = await fetch('/api/master/1');
        } else if (state.role === 'client') {
          userResponse = await fetch('/api/client/1');
        }

        const userData = await userResponse.json();
        setState((prevState) => ({
          ...prevState,
          user: userData,
        }));
      } catch (error) {
        console.error('Error loading default user:', error);
      }
    };

    loadDefaultUser();
  }, [state.role]);

  // ������ ��� �������� ������ � ����������� �� ����
  useEffect(() => {
    if (state.role === 'master') {
      fetchOffers();
    } else if (state.role === 'client') {
      fetchRequests();
    }
  }, [state.role]);

  return (
    <AppContext.Provider value={{ state, setRole, fetchOffers, fetchRequests, fetchNotifications }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
