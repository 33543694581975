import React, { useEffect, useState } from 'react';
import '../App.css';
import Modal from '../components/Modal';

function MasterDashboard() {
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await fetch('/api/offers');
        const data = await response.json();
        setOffers(data);
      } catch (error) {
        setError('Error fetching offers');
      }
    };

    fetchOffers();
  }, []);

  const handleCancelOffer = async (offerId) => {
    try {
      const response = await fetch(`/api/offers/${offerId}/hide`, { method: 'POST' });
      if (!response.ok) throw new Error('Failed to cancel offer');
      setOffers(offers.filter(offer => offer.offer_id !== offerId));
      setModalMessage('Offer successfully canceled');
    } catch (error) {
      setModalMessage(`Error: ${error.message}`);
    }
  };

  const handleSelectOffer = (offer) => setSelectedOffer(offer);

  const closeModal = () => setModalMessage('');

  return (
    <div className="dashboard">
      <h2>Your Offers</h2>
      {error && <p className="error">{error}</p>}
      <ul className="dashboard__list">
        {offers.map(offer => (
          <li key={offer.offer_id} className="dashboard__item">
            <p><strong>Request ID:</strong> {offer.request_id}</p>
            <p><strong>Price:</strong> {offer.price}</p>
            <p><strong>Completion Time:</strong> {new Date(offer.completion_time).toLocaleString()}</p>
            <button onClick={() => handleSelectOffer(offer)}>View Details</button>
            <button onClick={() => handleCancelOffer(offer.offer_id)}>Cancel Offer</button>
          </li>
        ))}
      </ul>
      {modalMessage && <Modal message={modalMessage} onClose={closeModal} />}
      {selectedOffer && (
        <Modal title="Offer Details" onClose={() => setSelectedOffer(null)}>
          <p><strong>Request ID:</strong> {selectedOffer.request_id}</p>
          <p><strong>Price:</strong> {selectedOffer.price}</p>
          <p><strong>Completion Time:</strong> {new Date(selectedOffer.completion_time).toLocaleString()}</p>
          <button onClick={() => setSelectedOffer(null)}>Close</button>
        </Modal>
      )}
    </div>
  );
}

export default MasterDashboard;
