import React, { useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';

function RequestFormPage() {
    const [formData, setFormData] = useState({
        phone: '',
        photos: [],
        address: '',
        description: '',
        proposalTimeLimit: '15',
        startDateTime: '',
        deadline: ''
    });

    const [createdRequest, setCreatedRequest] = useState(null);
    const [existingrequests, setExistingrequests] = useState([]);
    const [error, setError] = useState(null);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showConfirmDeactivate, setShowConfirmDeactivate] = useState(false);
    const [requestToDeactivate, setRequestToDeactivate] = useState(null);
    const [showJustEnded, setShowJustEnded] = useState(false);
    const [showArchived, setShowArchived] = useState(false);

    useEffect(() => {
        fetch('/api/requests')
            .then(response => response.json())
            .then(data => {
                const parsedData = data.map(request => ({
                    ...request,
                    photos: request.photos ? JSON.parse(request.photos) : [],
                    remainingTime: calculateRemainingTime(request.endTime)
                }));
                setExistingrequests(parsedData);
            })
            .catch(error => {
                console.error('Error fetching requests:', error);
                setError('Error fetching requests');
            });
    }, []);

    useEffect(() => {
        document.getElementById('phone').focus(); // ����� �� ���� "Phone" ��� ��������
    }, []);

const calculateRemainingTime = (endTime) => {
    if (!endTime) return 'N/A'; // ���� ��� ������� ���������, ���������� 'N/A'
    const remainingTime = Math.max(0, new Date(endTime).getTime() - new Date().getTime());
    return new Date(remainingTime).toISOString().substr(11, 8); // ���������� ���������� ����� � ������� ��:��:��
};


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = async (e) => {
        try {
            const files = Array.from(e.target.files);
            const compressedFiles = await Promise.all(files.map(file => compressImage(file)));
            setFormData({ ...formData, photos: compressedFiles });
        } catch (error) {
            console.error('Error compressing images:', error);
            setError('Error compressing images');
        }
    };

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 2000,
            useWebWorker: true,
            initialQuality: 0.85,
            fileType: 'image/jpeg'
        };
        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing file:', error);
            throw error;
        }
    };

    const handleGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setFormData({ ...formData, address: `Latitude: ${latitude}, Longitude: ${longitude}` });
                },
                (error) => {
                    console.error('Error fetching geolocation:', error);
                    setError('Error fetching geolocation');
                }
            );
        } else {
            setError('Geolocation is not supported by this browser');
        }
    };

const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('phone', formData.phone);
    formData.photos.forEach((photo, index) => {
        if (photo instanceof Blob) {
            data.append('photos', photo, `photo_${index}.jpg`);
        }
    });
    data.append('address', formData.address);
    data.append('description', formData.description);
    data.append('proposalTimeLimit', formData.proposalTimeLimit);
    if (formData.startDateTime) data.append('startDateTime', formData.startDateTime);
    if (formData.deadline) data.append('deadline', formData.deadline);

    fetch('/api/requests', {
        method: 'POST',
        body: data
    })
    .then(response => {
        if (!response.ok) {
            return response.text().then(text => { 
                if (text.includes('<!DOCTYPE html>')) {
                    throw new Error('Server returned an HTML page, indicating an internal error.');
                }
                throw new Error('Error submitting request');
            });
        }
        return response.json();
    })
    .then(data => {
        // ����������� ������ ��� �����������
        const newRequest = {
            ...data,
            photos: Array.isArray(data.photos) ? data.photos : JSON.parse(data.photos),
            remainingTime: calculateRemainingTime(data.endTime) // ������������ ���������� �����
        };

        // ��������� ���������
        setCreatedRequest(newRequest);
        setError(null);
        setExistingrequests([...existingrequests, newRequest]); // ��������� ����� ������ � ������������ ������
        setModalMessage('Request created successfully');
        setShowModal(true); // ���������� ��������� ����
    })
    .catch(error => {
        console.error('Error submitting request:', error);
        setError('Error submitting request: ' + error.message);
        setModalMessage('Error submitting request: ' + error.message);
        setShowModal(true); // ���������� ��������� �� ������ � ��������� ����
    });
};


    const handleUpdate = (id) => {
        fetch(`/api/requests/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => { throw new Error(err.error || 'Error updating request') });
            }
            return response.json();
        })
        .then(data => {
            const updatedrequests = existingrequests.map(req => 
                req.id === id ? { ...req, ...formData, photos: req.photos } : req
            );
            setExistingrequests(updatedrequests);
            setModalMessage('Request updated successfully');
            setShowModal(true);
        })
        .catch(error => {
            console.error('Error updating request:', error);
            setError('Error updating request: ' + error.message);
            setModalMessage('Error updating request: ' + error.message);
            setShowModal(true);
        });
    };

    const handleDeactivate = () => {
        if (!requestToDeactivate) return;

        fetch(`/api/requests/${requestToDeactivate.id}`, {
            method: 'DELETE'
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => { throw new Error(err.error || 'Error deactivating request') });
            }
            return response.json();
        })
        .then(data => {
            setError(null);
            setExistingrequests(existingrequests.filter(req => req.id !== requestToDeactivate.id));
            setModalMessage('Request deactivated successfully');
            setShowConfirmDeactivate(false);
            setShowModal(true);
        })
        .catch(error => {
            console.error('Error deactivating request:', error);
            setError('Error deactivating request: ' + error.message);
            setShowConfirmDeactivate(false);
            setShowModal(true);
        });
    };

    const updateRemainingTime = () => {
        setExistingrequests((prevrequests) => 
            prevrequests.map((request) => {
                const remainingTime = calculateRemainingTime(request.endTime);
                return { ...request, remainingTime };
            })
        );
    };

    useEffect(() => {
        const interval = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(interval);
    }, [existingrequests]);

    const closeModal = () => {
        setShowModal(false);
        setModalMessage('');
        setCreatedRequest(null);
    };

    const confirmDeactivate = (request) => {
        setRequestToDeactivate(request);
        setShowConfirmDeactivate(true);
    };

    const closeConfirmDeactivate = () => {
        setRequestToDeactivate(null);
        setShowConfirmDeactivate(false);
    };

    const formatTime = (timeInMs) => {
        const totalSeconds = Math.floor(timeInMs / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const sortedrequests = existingrequests.sort((a, b) => {
        const timeA = new Date(b.endTime).getTime() - new Date().getTime();
        const timeB = new Date(a.endTime).getTime() - new Date().getTime();
        return timeA - timeB;
    });

    const justEndedrequests = sortedrequests.filter(req => new Date(req.endTime).getTime() < new Date().getTime() && new Date(req.endTime).getTime() >= new Date().getTime() - 24 * 60 * 60 * 1000);
    const archivedrequests = sortedrequests.filter(req => new Date(req.endTime).getTime() < new Date().getTime() - 24 * 60 * 60 * 1000);
    const activerequests = sortedrequests.filter(req => new Date(req.endTime).getTime() >= new Date().getTime());

    const handleFocus = (e) => {
        e.target.setAttribute('autoComplete', 'on');
    };

    const handleBlur = (e) => {
        e.target.removeAttribute('autoComplete');
    };

    const handleSelectRequest = (request) => {
        setFormData({
            phone: request.phone,
            photos: Array.isArray(request.photos) ? request.photos : [],
            address: request.address,
            description: request.description,
            proposalTimeLimit: request.proposalTimeLimit,
            startDateTime: request.startDateTime,
            deadline: request.deadline
        });
        setCreatedRequest(request);
        setShowModal(true);
        document.getElementById('phone').focus(); // ��������� � ����� � ����� �� ������ ����
    };

    return (
        <div className="container">
            <h2>Active requests ({activerequests.length})</h2>
            <ul>
                {activerequests.map(request => (
                    <li key={request.id} className="request-item">
                        <div>
                            <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
                            <p>Description: {request.description}</p>
                            <p>Address: {request.address}</p>
                            <p>Phone: {request.phone}</p>
                            <p>Time Limit for Proposals: {request.proposalTimeLimit} minutes</p>
                            <p>Remaining Time: {formatTime(request.remainingTime)}</p>
                            {request.photos && request.photos.length > 0 && (
                                <div className="request-photos">
                                    {request.photos.map((photo, index) => (
                                        <img key={index} src={fixPhotoUrl(photo)} alt={`Request ${index}`} />
                                    ))}
                                </div>
                            )}
                        </div>
                        <div class="button-group">
                            <button onClick={() => handleSelectRequest(request)}>Edit</button>
                            <button onClick={() => confirmDeactivate(request)}>Deactivate</button>
                        </div>
                    </li>
                ))}
            </ul>  
            <div class="button-group">
            <button onClick={() => setShowJustEnded(!showJustEnded)}>{showJustEnded ? '- Just Ended' : '+ Just Ended'} ({justEndedrequests.length})</button>
            </div>
            {showJustEnded && (
                <ul>
                    {justEndedrequests.map(request => (
                        <li key={request.id} className="request-item">
                            <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
                            <p>Description: {request.description}</p>
                            <p>Address: {request.address}</p>
                            <p>Number of Offers: {request.offersCount || 'No offers from masters'}</p>
                            <p>Price Range: {request.priceRange || '-'}</p>
                            <p>Time Range: {request.timeRange || 'No offers from masters'}</p>
                            {request.photos && request.photos.length > 0 && (
                                <div className="request-photos">
                                    {request.photos.map((photo, index) => (
                                        <img key={index} src={fixPhotoUrl(photo)} alt={`Request ${index}`} />
                                    ))}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            )}
            <div class="button-group">
            <button onClick={() => setShowArchived(!showArchived)}>{showArchived ? '- Archive' : '+ Archive'} ({archivedrequests.length})</button>
            </div>
            {showArchived && (
                <ul>
                    {archivedrequests.map(request => (
                        <li key={request.id} className="request-item">
                            <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
                            <p>Description: {request.description}</p>
                            <p>Address: {request.address}</p>
                            <p>Number of Offers: {request.offersCount || 'No offers from masters'}</p>
                            <p>Price Range: {request.priceRange || '-'}</p>
                            <p>Time Range: {request.timeRange || 'No offers from masters'}</p>
                            {request.photos && request.photos.length > 0 && (
                                <div className="request-photos">
                                    {request.photos.map((photo, index) => (
                                        <img key={index} src={fixPhotoUrl(photo)} alt={`Request ${index}`} />
                                    ))}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            )}
            {error && <p className="error">{error}</p>}
            <div id="editForm">
                <form onSubmit={handleSubmit}>
                    <label>
                        Phone<span style={{ color: 'red' }}>*</span>:
                        <input id="phone" type="tel" name="phone" value={formData.phone} onChange={handleChange} required onFocus={handleFocus} onBlur={handleBlur}/>
                    </label>
                    <label>
                        Photos:
                        <input type="file" name="photos" onChange={handleFileChange} multiple onFocus={handleFocus} onBlur={handleBlur}/>
                    </label>
                    <label>
                        Address<span style={{ color: 'red' }}>*</span>:
                        <input type="text" name="address" value={formData.address} onChange={handleChange} required onFocus={handleFocus} onBlur={handleBlur}/>
                        <button type="button" onClick={handleGeoLocation}>Use My Location</button>
                    </label>
                    <label>
                        Description<span style={{ color: 'red' }}>*</span>:
                        <textarea name="description" value={formData.description} onChange={handleChange} required onFocus={handleFocus} onBlur={handleBlur}/>
                    </label>
                    <label>
                        Time Limit for Proposals<span style={{ color: 'red' }}>*</span>:
                        <select name="proposalTimeLimit" value={formData.proposalTimeLimit} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}>
                            <option value="15">15 minutes</option>
                            <option value="30">30 minutes</option>
                            <option value="60">60 minutes</option>
                            <option value="untilStart">Until Start</option>
                            <option value="1hourBefore">1 Hour Before Start</option>
                        </select>
                    </label>
                    <label>
                        Start Date and Time:
                        <input type="datetime-local" name="startDateTime" value={formData.startDateTime} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}/>
                    </label>
                    <label>
                        Deadline for Completion:
                        <input type="datetime-local" name="deadline" value={formData.deadline} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}/>
                    </label>
                    <button type="submit">Submit</button>
                </form>
            </div>
            
            {/* ��������� ���� ��� ������������� ����������� */}
            {showConfirmDeactivate && (
                <div className="modal-overlay">
                    <div className="modal-content animated">
                        <h3>Are you sure you want to deactivate this request?</h3>
                        <div className="modal-buttons">
                            <button onClick={handleDeactivate}>Yes</button>
                            <button onClick={closeConfirmDeactivate}>No</button>
                        </div>
                    </div>
                </div>
            )}

            {/* ��������� ���� ��� ���������� � ������ */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content animated">
                        {createdRequest && (
                            <>
                                <h3>Request Updated</h3>
                                <p>Description: {createdRequest.description}</p>
                                <p>Address: {createdRequest.address}</p>
                                <p>Phone: {createdRequest.phone}</p>
                                <p>Time Limit for Proposals: {createdRequest.proposalTimeLimit} minutes</p>
                                <p>Remaining Time: {formatTime(createdRequest.remainingTime)}</p>
                                {createdRequest.photos && createdRequest.photos.length > 0 ? (
                                    <div className="modal-photos">
                                        {createdRequest.photos.map((photo, index) => (
                                            <img key={index} src={fixPhotoUrl(photo)} alt={`Request ${index}`} />
                                        ))}
                                    </div>
                                ) : (
                                    <p>No photos available</p>
                                )}
                                <div className="modal-buttons">
                                    <button onClick={closeModal}>OK</button>
                                </div>
                            </>
                        )}
                        {modalMessage && !createdRequest && <p>{modalMessage}</p>}
                        {!createdRequest && <button onClick={closeModal}>Close</button>}
                    </div>
                </div>
            )}
        </div>
    );

    function fixPhotoUrl(photoUrl) {
        // �������� � ������������� ����, ����� �������� ������������ "/uploads/uploads"
        if (photoUrl.startsWith('/uploads/uploads')) {
            return photoUrl.replace('/uploads/uploads', '/uploads');
        }
        return photoUrl;
    }
}

export default RequestFormPage;
