import React, { useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';

function OfferForm({ requestId, onSubmit }) {
  const { state } = useContext(AppContext);
  const [completionTime, setCompletionTime] = useState('');
  const [price, setPrice] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!completionTime) {
      alert("Please select a completion date and time");
      return;
    }

    const offer = {
      completionTime: new Date(completionTime).toISOString(),
      price: parseFloat(price),
    };

    if (isNaN(offer.price) || offer.price <= 0) {
      alert("Please enter a valid price");
      return;
    }

    onSubmit(requestId, offer);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Completion Date and Time<span style={{ color: 'red' }}>*</span>:
        <input
          type="datetime-local"
          name="completionTime"
          value={completionTime}
          onChange={(e) => setCompletionTime(e.target.value)}
          required
        />
      </label>
      <label>
        Price<span style={{ color: 'red' }}>*</span>:
        <input
          type="number"
          name="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Enter price"
          min="0"
          required
        />
      </label>       
      <div class="button-group">
      <button type="submit">Submit Offer</button>
      </div>
    </form>
  );
}

export default OfferForm;
