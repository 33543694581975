import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import '../App.css';

function ClientDashboard() {
  const [requests, setRequests] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [error, setError] = useState(null);
  const [modalMessage, setModalMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState({ show: false, action: null });

  useEffect(() => {
    // �������� ������ �������
    const fetchRequests = async () => {
      try {
        const response = await fetch('/api/requests');
        const data = await response.json();
        const parsedData = data.map(request => ({
          ...request,
          photos: request.photos ? JSON.parse(request.photos) : [],
          remainingTime: calculateRemainingTime(request.endTime),
        }));
        setRequests(parsedData);
      } catch (error) {
        setError('Error fetching requests');
      }
    };

    // �������� ����������� �� ������� �������
    const fetchOffers = async () => {
      try {
        const response = await fetch('/api/offers');
        const data = await response.json();
        setOffers(data);
      } catch (error) {
        setError('Error fetching offers');
      }
    };

    fetchRequests();
    fetchOffers();
  }, []);

  const calculateRemainingTime = (endTime) => {
    if (!endTime) return 'N/A';
    const remainingTime = Math.max(0, new Date(endTime).getTime() - new Date().getTime());
    return new Date(remainingTime).toISOString().substr(11, 8); // ���������� � ������� ��:��:��
  };

  const handleAcceptOffer = async (requestId, offerId) => {
    try {
      const response = await fetch(`/api/requests/${requestId}/accept`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ offerId }),
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(err.error || 'Error accepting offer');
      }

      setModalMessage('Offer accepted successfully');
      setRequests(prevRequests =>
        prevRequests.map(request =>
          request.id === requestId
            ? { ...request, status: 'accepted', acceptedOffer: offerId }
            : request
        )
      );
    } catch (error) {
      console.error('Error accepting offer:', error);
      setError('Error accepting offer: ' + error.message);
    }
  };

  const handleHideOffer = async (offerId) => {
    try {
      const response = await fetch(`/api/offers/${offerId}/hide`, {
        method: 'POST',
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(err.error || 'Error hiding offer');
      }

      setOffers(prevOffers => prevOffers.filter(offer => offer.offer_id !== offerId));
      setModalMessage('Offer hidden successfully');
    } catch (error) {
      console.error('Error hiding offer:', error);
      setError('Error hiding offer: ' + error.message);
    }
  };

  const handleCancelRequest = async (requestId) => {
    try {
      const response = await fetch(`/api/requests/${requestId}/cancel`, {
        method: 'POST',
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(err.error || 'Error cancelling request');
      }

      setRequests(prevRequests =>
        prevRequests.map(request =>
          request.id === requestId ? { ...request, status: 'cancelled' } : request
        )
      );
      setModalMessage('Request cancelled successfully');
    } catch (error) {
      console.error('Error cancelling request:', error);
      setError('Error cancelling request: ' + error.message);
    }
  };

  const handleCompleteRequest = async (requestId) => {
    try {
      const response = await fetch(`/api/requests/${requestId}/complete`, {
        method: 'POST',
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(err.error || 'Error completing request');
      }

      setRequests(prevRequests =>
        prevRequests.map(request =>
          request.id === requestId ? { ...request, status: 'completed' } : request
        )
      );
      setModalMessage('Request completed successfully');
    } catch (error) {
      console.error('Error completing request:', error);
      setError('Error completing request: ' + error.message);
    }
  };

  const openConfirmModal = (action) => {
    setShowConfirmModal({ show: true, action });
  };

  const closeModal = () => {
    setModalMessage('');
    setShowConfirmModal({ show: false, action: null });
  };

  return (
    <div className="container">
      <h2>Your Requests</h2>
      {error && <p className="error">{error}</p>}
      <ul>
        {requests.map(request => (
          <li key={request.id} className="request-item">
            <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
            <p>Description: {request.description}</p>
            <p>Address: {request.address}</p>
            <p>Phone: {request.phone}</p>
            <p>Time Limit for Proposals: {request.proposalTimeLimit} minutes</p>
            <p>Remaining Time: {calculateRemainingTime(request.endTime)}</p>
            {request.photos.length > 0 && (
              <div className="request-photos">
                {request.photos.map((photo, index) => (
                  <img key={index} src={photo} alt={`Request ${index}`} />
                ))}
              </div>
            )}
            {request.status !== 'accepted' && (
              <div className="offers-list">
                <h4>Offers:</h4>
                <ul>
                  {offers
                    .filter(offer => offer.request_id === request.id)
                    .map(offer => (
                      <li key={offer.offer_id} className="offer-item">
                        <p>Price: {offer.price}</p>
                        <p>Completion Time: {new Date(offer.completion_time).toLocaleString()}</p>
                        <button onClick={() => handleAcceptOffer(request.id, offer.offer_id)}>Accept</button>
                        <button onClick={() => handleHideOffer(offer.offer_id)}>Hide</button>
                      </li>
                    ))}
                </ul>
              </div>
            )}
            {request.status === 'accepted' && (
              <div>
                <h4>Accepted Offer:</h4>
                {/* ���������� ���� � ������ ������� */}
                <button onClick={() => openConfirmModal(() => handleCancelRequest(request.id))}>Cancel Request</button>
                <button onClick={() => openConfirmModal(() => handleCompleteRequest(request.id))}>Complete Request</button>
                <button>Chat with Master</button>
              </div>
            )}
          </li>
        ))}
      </ul>
      {modalMessage && <Modal message={modalMessage} onClose={closeModal} />}
      {showConfirmModal.show && (
        <Modal onClose={closeModal} onConfirm={showConfirmModal.action}>
          <p>Are you sure?</p>
        </Modal>
      )}
    </div>
  );
}

export default ClientDashboard;
