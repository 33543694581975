import React from 'react';
import '../App.css';

function Modal({ title, message, onClose, onConfirm, children }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{title}</h3>
        {children || <p>{message}</p>}
        <div className="modal-buttons">
          {onConfirm ? (
            <>
              <button onClick={onConfirm} className="confirm-button">Yes</button>
              <button onClick={onClose} className="cancel-button">No</button>
            </>
          ) : (
            <button onClick={onClose}>Close</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
