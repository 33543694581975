// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import RequestFormPage from './pages/RequestFormPage';
import Offers from './pages/Offers';
import ChatPage from './pages/ChatPage';
import AdminDashboard from './pages/AdminDashboard';
import ClientDashboard from './pages/ClientDashboard';
import MasterDashboard from './pages/MasterDashboard';
import Navigation from './components/Navbar';
import { AppProvider } from './context/AppContext';
import MasterRegister from './pages/MasterRegister';
import MasterLogin from './pages/MasterLogin';
import MasterSettingsPage from './pages/MasterSettingsPage'; // ���������� ���������

function App() {
  return (
    <AppProvider>
      <Router>
        <Navigation />
        <div className="container mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/request" element={<RequestFormPage />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/client-dashboard" element={<ClientDashboard />} />
            <Route path="/master-dashboard" element={<MasterDashboard />} />
            <Route path="/master-register" element={<MasterRegister />} />
            <Route path="/master-login" element={<MasterLogin />} />
            <Route path="/master-settings" element={<MasterSettingsPage />} /> {/* �������� ������� */}
          </Routes>
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;
