import React, { useState, useEffect } from 'react';
import imageCompression from 'browser-image-compression';

function MasterSettingsPage() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('/api/master/1');
        const data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleFileChange = async (e) => {
    try {
      const files = Array.from(e.target.files);
      const compressedFiles = await Promise.all(files.map(file => compressImage(file)));
      setProfile({ ...profile, photo: compressedFiles[0] });
    } catch (error) {
      console.error('Error compressing images:', error);
      alert('Error compressing images');
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
      initialQuality: 0.85,
      fileType: 'image/jpeg'
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing file:', error);
      throw error;
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const formData = new FormData();
      formData.append('master_id', profile.master_id);
      formData.append('name', profile.name);
      formData.append('email', profile.email);
      formData.append('phone', profile.phone);
      formData.append('location', profile.location);
      formData.append('radius', profile.radius);
      if (profile.photo instanceof Blob) {
        formData.append('photo', profile.photo); // ��������� ���� � �����
      }

      const response = await fetch('/api/master/profile', {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        alert('Profile updated successfully');
      } else {
        alert('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  const handleSetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setProfile({
            ...profile,
            location: `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`
          });
        },
        (error) => {
          console.error('Error fetching geolocation:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!profile) {
    return <p>Error loading profile.</p>;
  }

  return (
    <div className="container">
      <h2>Master Settings</h2>
      <label>Name:</label>
      <input
        type="text"
        value={profile.name || ''}
        onChange={(e) => setProfile({ ...profile, name: e.target.value })}
      />
      <label>Email:</label>
      <input
        type="email"
        value={profile.email || ''}
        onChange={(e) => setProfile({ ...profile, email: e.target.value })}
      />
      <label>Phone:</label>
      <input
        type="text"
        value={profile.phone || ''}
        onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
      />
      <label>Location:</label>
      <input
        type="text"
        value={profile.location || ''}
        readOnly
      />
      <button onClick={handleSetLocation}>Update Location</button>
      <label>Photo:</label>
      <input
        type="file"
        onChange={handleFileChange}
      />
      {/* ����������� ���� */}
      {profile.photo && (
        <div>
          <img src={profile.photo} alt="Profile" style={{ width: '150px', height: '150px' }} />
        </div>
      )}
      <label>Work Radius (km):</label>
      <select
        value={profile.radius || 1}
        onChange={(e) => setProfile({ ...profile, radius: e.target.value })}
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <button onClick={handleUpdateProfile}>Save Settings</button>
      <h3>Order Statistics</h3>
      <p>Completed Orders: {profile.completed_orders || 0}</p>
      <p>On Time: {profile.stats?.onTime || 0}</p>
      <p>Delayed: {profile.stats?.delayed || 0}</p>
    </div>
  );
}

export default MasterSettingsPage;
