import React, { useEffect, useState } from 'react';
import '../App.css'; // ���������� ���� �����

function AdminDashboard() {
  const [requests, setRequests] = useState([]);
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await fetch('/api/requests');
        const data = await response.json();
        setRequests(data);
      } catch (error) {
        setError('Error fetching requests');
      }
    };

    const fetchOffers = async () => {
      try {
        const response = await fetch('/api/offers');
        const data = await response.json();
        setOffers(data);
      } catch (error) {
        setError('Error fetching offers');
      }
    };

    fetchRequests();
    fetchOffers();
  }, []);

  return (
    <div className="dashboard">
      <h2 className="dashboard__title">All Requests</h2>
      {error && <p className="error">{error}</p>}
      <ul className="dashboard__list">
        {requests.map(request => (
          <li key={request.id} className="dashboard__item">
            <p><strong>Description:</strong> {request.description}</p>
            <h4>Offers:</h4>
            <ul className="dashboard__offers-list">
              {offers
                .filter(offer => offer.request_id === request.id)
                .map(offer => (
                  <li key={offer.offer_id} className="dashboard__offers-item">
                    <p><strong>Price:</strong> {offer.price}</p>
                    <p><strong>Completion Time:</strong> {new Date(offer.completion_time).toLocaleString()}</p>
                  </li>
                ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminDashboard;
