import React, { useEffect, useState } from 'react';
import OfferForm from './OfferForm';
import Modal from '../components/Modal';
import '../App.css';

function Offers() {
  const [requests, setRequests] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [modalMessage, setModalMessage] = useState('');
  const [showJustEnded, setShowJustEnded] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await fetch('/api/requests');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const parsedData = data.map(request => ({
          ...request,
          photos: request.photos ? JSON.parse(request.photos) : [],
          remainingTime: calculateRemainingTime(request.endTime),
        }));
        setRequests(parsedData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchRequests();
  }, []);

const calculateRemainingTime = (endTime) => {
    if (!endTime) return 'N/A'; // ���� ��� ������� ���������, ���������� 'N/A'
    const remainingTime = Math.max(0, new Date(endTime).getTime() - new Date().getTime());
    return new Date(remainingTime).toISOString().substr(11, 8); // ���������� ���������� ����� � ������� ��:��:��
};


  useEffect(() => {
    const interval = setInterval(() => {
      setRequests((requests) =>
        requests.map((request) => ({
          ...request,
          remainingTime: calculateRemainingTime(request.endTime),
        }))
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [requests]);

  const handleSelectRequest = (request) => {
    setSelectedRequest(request);
  };

  const handleOfferSubmit = async (requestId, offer) => {
    try {
      const response = await fetch(`/api/requests/${requestId}/offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(offer),
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(err.error || 'Error submitting offer');
      }

      const updatedOffer = await response.json();
      setModalMessage('Offer submitted successfully');

      // ��������� ��������� ������ � ������ �������
      setRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === requestId
            ? { ...request, offers: [...(request.offers || []), updatedOffer] }
            : request
        )
      );
    } catch (error) {
      console.error('Error submitting offer:', error);
      setModalMessage('Error submitting offer: ' + error.message);
    }
  };

  const closeModal = () => {
    setModalMessage('');
  };

  const formatTime = (timeInMs) => {
    const totalSeconds = Math.floor(timeInMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const sortedRequests = requests.sort((a, b) => {
    const timeA = new Date(a.endTime).getTime() - new Date().getTime();
    const timeB = new Date(b.endTime).getTime() - new Date().getTime();
    return timeA - timeB;
  });

  const justEndedRequests = sortedRequests.filter(
    (req) =>
      new Date(req.endTime).getTime() < new Date().getTime() &&
      new Date(req.endTime).getTime() >= new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const archivedRequests = sortedRequests.filter(
    (req) => new Date(req.endTime).getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const activeRequests = sortedRequests.filter(
    (req) => new Date(req.endTime).getTime() >= new Date().getTime()
  );

  return (
    <div className="container">
      <h2>Available Requests ({activeRequests.length})</h2>
      {error && <p className="error">Error: {error}</p>}
      <ul>
        {activeRequests.map((request) => (
          <li
            key={request.id}
            onClick={() => handleSelectRequest(request)}
            className="request-item"
          >
            <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
            <p>Description: {request.description}</p>
            <p>Address: {request.address}</p>
            <p>Phone: {request.phone}</p>
            <p>Time Limit for Proposals: {request.proposalTimeLimit} minutes</p>
            <p>Remaining Time: {formatTime(request.remainingTime)}</p>
            {request.startDateTime && (
              <p>Start Time: {new Date(request.startDateTime).toLocaleString()}</p>
            )}
            {request.deadline && (
              <p>Deadline: {new Date(request.deadline).toLocaleString()}</p>
            )}
            {request.photos.length > 0 ? (
              <div className="photos-container">
                {request.photos.map((photo, index) => (
                  <img key={index} src={photo} alt={`Request ${index}`} className="photo" />
                ))}
              </div>
            ) : (
              <p>No photos available</p>
            )}
            <div className="button-group">
              <button onClick={() => handleSelectRequest(request)}>Respond</button>
            </div>
            {request.minCompletionTime && (
              <p>Min Completion Time: {formatDuration(request.minCompletionTime)}</p>
            )}
            {request.maxCompletionTime && (
              <p>Max Completion Time: {formatDuration(request.maxCompletionTime)}</p>
            )}
          </li>
        ))}
      </ul>
      {selectedRequest && <OfferForm requestId={selectedRequest.id} onSubmit={handleOfferSubmit} />}
      <div className="button-group">
        <button onClick={() => setShowJustEnded(!showJustEnded)}>
          {showJustEnded ? '- Just Ended' : '+ Just Ended'} ({justEndedRequests.length})
        </button>
      </div>
      {showJustEnded && (
        <ul>
          {justEndedRequests.map((request) => (
            <li key={request.id} className="request-item">
              <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
              <p>Description: {request.description}</p>
              <p>Address: {request.address}</p>
              <p>Number of Offers: {request.offersCount || 'No offers from masters'}</p>
              <p>Price Range: {request.priceRange || '-'}</p>
              <p>Time Range: {request.timeRange || 'No offers from masters'}</p>
              {request.photos.length > 0 ? (
                <div className="photos-container">
                  {request.photos.map((photo, index) => (
                    <img key={index} src={photo} alt={`Request ${index}`} className="photo" />
                  ))}
                </div>
              ) : (
                <p>No photos available</p>
              )}
            </li>
          ))}
        </ul>
      )}
      <div className="button-group">
        <button onClick={() => setShowArchived(!showArchived)}>
          {showArchived ? '- Archive' : '+ Archive'} ({archivedRequests.length})
        </button>
      </div>
      {showArchived && (
        <ul>
          {archivedRequests.map((request) => (
            <li key={request.id} className="request-item">
              <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
              <p>Description: {request.description}</p>
              <p>Address: {request.address}</p>
              <p>Number of Offers: {request.offersCount || 'No offers from masters'}</p>
              <p>Price Range: {request.priceRange || '-'}</p>
              <p>Time Range: {request.timeRange || 'No offers from masters'}</p>
              {request.photos.length > 0 ? (
                <div className="photos-container">
                  {request.photos.map((photo, index) => (
                    <img key={index} src={photo} alt={`Request ${index}`} className="photo" />
                  ))}
                </div>
              ) : (
                <p>No photos available</p>
              )}
            </li>
          ))}
        </ul>
      )}
      {modalMessage && <Modal message={modalMessage} onClose={closeModal} />}
    </div>
  );
}

export default Offers;
